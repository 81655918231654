import React, { useContext } from "react";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  Image,
  VStack,
  StackDivider,
  Divider,
  Select,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import IconCardXL from "../../layouts/IconCardXL";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto1/Picto1.png";
import Picto2 from "../Step3/picto1/Picto1.png";
import Picto3 from "../Step3/picto1/Picto2.png";
import Picto4 from "../Step3/picto1/Picto3.png";
import RadioCard from "../../layouts/RadioCard";

const StepOne = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);

  const calculateConsoAnnuelleBallon = () => {
    if (formData.ballonThermoSelected === "0") {
      return 0; // Retourne 0 si le ballon thermo sélectionné est 0
    } else {
      const N12 = formData.depenseECS / 0.17;
      return N12 * 0.25; // Calcul habituel si le ballon thermo n'est pas 0
    }
  };
  formData.consoAnnuelleBallon = calculateConsoAnnuelleBallon();

  const calculateDepenseFutureBallon = () => {
    if (formData.ballonThermoSelected === "0") {
      return 0; // Si le ballon thermo est sélectionné à "0", retourner 0 pour la dépense future
    }
    return formData.consoAnnuelleBallon * 0.17; // Calcul habituel si le ballon n'est pas à "0"
  };
  formData.depenseFutureBallon = calculateDepenseFutureBallon();

  const calculateCoutProjetBallon = () => {
    if (formData.ballonThermoSelected === "Oui") {
      return 3490;
    } else {
      return 0;
    }
  };
  formData.coutProjetBallon = calculateCoutProjetBallon();

  const maPrimeBallonMatrix = {
    bleu: 1200,
    jaune: 800,
    rose: 0,
    violet: 400,
  };

  const calculateMaPrimeBallon = () => {
    if (formData.ballonThermoSelected === "0") {
      return 0; // Si aucun ballon thermodynamique n'est sélectionné, retourner 0 pour la prime
    }
    const couleurAide = formData.aideColor.toLowerCase();
    return maPrimeBallonMatrix[couleurAide] || 0; // Retourne la prime selon la couleur d'aide, ou 0 si non définie
  };

  formData.maPrimeBallon = calculateMaPrimeBallon();

  const calculateAidesCeeBallon = () => {
    if (formData.ballonThermoSelected === "0") {
      return 0; // Si aucun ballon thermodynamique n'est sélectionné, retourner 0 pour les aides
    }
    
    // Vérification de la couleur d'aide
    if (formData.aideColor === "jaune" || formData.aideColor === "rose" || formData.aideColor === "violet") {
      return 97;
    } else if (formData.aideColor === "bleu") {
      return 114;
    } else {
      return 0;
    }
  };  

  formData.aidesCeeBallon = calculateAidesCeeBallon();

  const calculateDepenseFuturePompe = () => {
    const F21 = formData.pompeSelected ? parseInt(formData.pompeSelected) : 0;
    const F23 = formData.depenseECS;
    if (F21 === 0) {
      return 0;
    } else {
      const F22 = (F21 / 5) * 1000;
      return F22 * 0.17;
    }
  };
  formData.depenseFuturePompe = calculateDepenseFuturePompe();


  // Modifier ma fonction pour accepter 4 produits 16 et 4 a 18 en puissance 

  const coutProjetPompeMatrix = {
    "0": 0,
    "Pompe air eau Ariston Nimbus : 80 S (8 kw)": 16000,
    "Pompe air eau Thompson Ether (10 kw)": 16000,
    "Pompe air eau Atlantic Duo Ballon integre (11 kw)": 23450,
    "Pompe air eau Ariston Nimbus : 120 S (12 kw)": 18000,
    "Pompe air eau Atlantic Duo Ballon integre (14 kw)": 24450,
    "Pompe air eau Thompson Ether (14 kw)": 18600,
    "Pompe air eau Daikin Altherma 3H (14 kw)": 22500,
    "Pompe air eau Daikin Erla MT plancher chauffant (14 kw)": 21500,
    "Pompe air eau Ariston Nimbus : 150 S (15 kw)": 21450,
    "Pompe air eau Atlantic Duo Ballon integre (16 kw)": 25450,
    "Pompe air eau Thompson Ether (16 kw)": 21900,
    "Pompe air eau Daikin Altherma 3H (16 kw)": 22900,
    "Pompe air eau Daikin Erla MT plancher chauffant (16 kw)": 21900,
    "Pompe air eau Daikin Altherma 3H (18 kw)": 23450,
    "Pompe air eau FHE Inelio Mono-bloc Tri-phasé (22 kw)": 24500,
    "Pompe air eau FHE Inelio Mono-bloc Tri-phasé (26 kw)": 26500,
    "Pompe air eau FHE Inelio Mono-bloc Tri-phasé (30 kw)": 28500
};

const calculateCoutProjetPompe = () => {
    const selectedModel = formData.pompeModeleSelected;
    const coutProjet = coutProjetPompeMatrix[selectedModel];
    console.log(formData.coutProjetPompe, 'formData.coutProjetPompe')
    return coutProjet !== undefined ? coutProjet : 0;
};

formData.coutProjetPompe = calculateCoutProjetPompe();

  const calculateMaPrimePompe = () => {
    const F21 = formData.pompeSelected;
    const couleurAide = formData.aideColor.toLowerCase(); // Ensure case insensitivity

    // Check if no pump is selected and the aid color is rose
    if (F21 === "0" || couleurAide === "rose") {
      return 0;
    }

    const maPrimeValues = {
      bleu: 5000,
      jaune: 4000,
      rose: 0,
      violet: 3000,
    };

    return maPrimeValues[couleurAide] || 0;
};

formData.maPrimePompe = calculateMaPrimePompe();



const calculateAidesCeePompe = () => {
  const pompeSelected = formData.pompeSelected;
  const sourceType = formData.sourceType;
  const couleurAide = formData.aideColor;

  // Si la pompe n'est pas sélectionnée ou si le type de source est "Électricité", retournez 0
  if (pompeSelected === "0" || sourceType === "ELECTRICITE" ||sourceType === "CONDENSATION" ) {
      return 0;
  }

  // Utilisez une matrice unique pour toutes les sources
  const aidesCeeMatrix = {
      bleu: 5000,
      jaune: 5000,
      rose: 3500,
      violet: 3500,
  };

  return aidesCeeMatrix[couleurAide] || 0;
};


  formData.aidesCeePompe = calculateAidesCeePompe();

  const calculateProductionAnnuellePanneaux = () => {
    const T21 = parseFloat(formData.panneauxSelected);
    const U12 = formData.solarCoefficient;

    const productionAnnuelle = T21 * 425 * U12 * 1;

    return productionAnnuelle * 0.84 || 0;
  };

  formData.productionAnnuellePanneaux = calculateProductionAnnuellePanneaux();

  const calculateDepenseFuturePanneaux = () => {
    if (formData.panneauxSelected === "0") {
      return 0; // Retourne 0 si aucun panneau n'est sélectionné
    }
    const T22 = parseFloat(formData.consoAnnuellePanneaux);
    const T23 = parseFloat(formData.productionAnnuellePanneaux);
    const depenseFutureBase = (T22 - T23) * 0.17; // Calcule la dépense future basée sur la consommation et la production

    // Calculez 10% de la dépense annuelle
    const minDepenseFuture = T22 * 0.1; // 10% de la dépense annuelle

    // Assurez-vous que la dépense future ne soit pas inférieure à 10% de la dépense annuelle
    let depenseFuture = Math.max(depenseFutureBase, minDepenseFuture);

    // Appliquez une multiplication supplémentaire de 0.17 à la dépense future
    depenseFuture *= 0.17;

    return Math.round(depenseFuture);
  };

  formData.depenseFuturePanneaux = calculateDepenseFuturePanneaux();

  const calculateCoutProjetPanneaux = () => {
    const coutTable = {
      6: 14650,
      7: 15650,
      8: 16650,
      9: 19650,
      10: 21700,
      11: 22700,
      12: 23700,
      13: 25800,
      14: 26500,
      15: 27790,
      16: 28800,
      17: 29790,
      18: 30790,
      19: 32890,
      20: 33900,
      21: 34900,
      9.01: 23690,
      150: 31690,
    };
    const selectedPanels = parseInt(formData.panneauxSelected);
    const coutProjet = coutTable[selectedPanels] || 0;
    return coutProjet;
  };
  formData.coutProjetPanneaux = calculateCoutProjetPanneaux();

  const calculatePrimeAutoConsoPanneaux = () => {
    const primeAutoConsoMatrix = {
      6: 561,
      7: 654.50,
      8: 544,
      9: 612,
      10: 680,
      11: 748,
      12: 816,
      13: 884,
      14: 952,
      15: 1020,
      16: 1088,
      17: 1156,
      18: 1224,
      19: 1292,
      20: 1360,
      21: 1428,
    };

    const selectedPanels = parseInt(formData.panneauxSelected);
    const primeAutoConso = primeAutoConsoMatrix[selectedPanels] || 0;
    return primeAutoConso;
  };

  // Example usage:
  formData.primeAutoConsoPanneaux = calculatePrimeAutoConsoPanneaux();

  const recuperationTvaMatrix = {
    6: 0,
    7: 0,
    8: 2022.8,
    9: 2439.41,
    10: 2614.42,
    11: 2743.16,
    12: 2805,
    13: 3188.32,
    14: 3405,
    15: 3511.66,
    16: 3728.33,
    17: 4111.66,
    18: 4328.44,
    19: 4325,
    20: 4541.668,
    21: 4958.2,
  };

  const calculateRecuperationTvaPanneaux = (selectedPanels) => {
    const parsedSelectedPanels = parseInt(selectedPanels);
    const recuperationTva = recuperationTvaMatrix[parsedSelectedPanels];
    return recuperationTva || 0;
  };

  const selectedPanels = formData.panneauxSelected;
  formData.recuperationTvaPanneaux =
    calculateRecuperationTvaPanneaux(selectedPanels);

  formData.coutProjet =
    formData.coutProjetPompe +
    formData.coutProjetBallon +
    formData.coutProjetPanneaux;
  formData.totalAide =
    formData.aidesCeePompe +
    formData.maPrimePompe +
    formData.maPrimeBallon +
    formData.aidesCeeBallon +
    formData.primeAutoConsoPanneaux +
    formData.recuperationTvaPanneaux;

  formData.coutProjetAideDeduite = formData.coutProjet - formData.totalAide;

  function calculateBillOver20Years() {
    let currentResult =
      formData.factureElectricite + formData.factureSecondaire;
    let resultArray = [currentResult];

    for (let i = 1; i < 20; i++) {
      currentResult *= 1.07;
      resultArray.push(currentResult);
    }

    const total = resultArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return total;
  }

  formData.coutFacture20ans = calculateBillOver20Years();

  //Modifier fonction prendre formData.consolAnnuelle et ajoute 7% en boucle jusqua 20 fois
  function calculateCoutFacture20ansAvecProjet() {
    let E5;
    if (formData.sourceType === "ELECTRICITE") {
      if (formData.energieType === "Ballon") {
        const X = formData.factureElectricite - formData.depenseECS;
        E5 = formData.depenseFuturePanneaux - X;
      } else if (formData.energieType === "Chaudiere") {
        E5 = formData.factureElectricite - formData.depenseFuturePanneaux;
      }
    } else {
      E5 =
        formData.factureElectricite +
        formData.factureSecondaire -
        formData.depenseFuturePanneaux;
    }

    let factureTotal = 0;
    let economiesTotal = 0;
    for (let year = 1; year <= 20; year++) {
      factureTotal += formData.consoAnnuelle * Math.pow(1.07, year); // Use formData.consoAnnuelle for factureTotal
      economiesTotal += E5 * Math.pow(1.07, year); // Use E5 for economiesTotal
    }

    formData.coutFacture20ansAvecProjet = formData.consoAnnuelle - E5;

    return formData.coutFacture20ansAvecProjet;
  }

  formData.coutFacture20ansAvecProjet = calculateCoutFacture20ansAvecProjet();

  function calculateGainSur20Ans() {
    return (
      formData.coutFacture20ans -
      formData.coutFacture20ansAvecProjet -
      formData.coutProjetAideDeduite
    );
  }
  console.log(formData);

  formData.gainSur20ans = calculateGainSur20Ans();

  // const handlePompeModeleChange = (value) => {
  //   if (value === "0") {
  //     // Seulement mettre à jour depenseChauffage à 0 si la condition est remplie
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       pompeModeleSelected: value,
  //       depenseChauffage: 0,
  //     }));
  //   } else {
  //     // Sinon, juste mettre à jour pompeModeleSelected
  //     setFormData((prevState) => ({
  //       ...prevState,
  //       pompeModeleSelected: value,
  //     }));
  //   }
  // };

  // formData.depenseChauffage = handlePompeModeleChange();

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Projet du client
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Sélectionnez le projet adapté pour le client
          </Text>
        </Box>
        <Button
          ml={"61%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box
              display={"flex"}
              mr={"50px"}
              flex={"1"}
              flexDirection={"column"}
            >
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Pompe a chaleur
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Projet d'action 1
              </Text>
            </Box>
            <Box
              display={"flex"}
              flex={"1"}
              mr={"50px"}
              flexDirection={"column"}
            >
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Ballon Thermodynamique
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Projet d'action 2
              </Text>
            </Box>{" "}
            <Box display={"flex"} flex={"1"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Panneaux Photovoltaïques
              </Heading>

              <Text fontSize="17px" fontWeight="400" color="#8C8C8C" mb={8}>
                Projet d'action 3
              </Text>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              w={["230px", "230px", "260px", "338px"]}
              h="546px"
              borderRadius="20px"
              padding="20px"
              borderWidth="1px"
              borderColor="#E2E8F0"
            >
              <Box
                boxSize={["230px", "230px", "230px", "300px"]}
                borderRadius="20px"
                bg="#F7F9FE"
                alignItems={"center"}
                display={"flex"}
                justifyContent={"center"}
              >
                <Image
                  src={Picto3} // Remplacez Picto3 par le chemin d'accès correct à votre icône
                  boxSize="50px"
                />
              </Box>
              <VStack
                mt={"20px"}
                divider={<StackDivider borderColor="#CBD5E0" />}
                align="stretch"
                w="full"
              >
                {/* Les éléments Label et Account */}
                <Flex justifyContent="space-between" mb={2}>
                  {/* <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Label
                  </Text>
                  <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Account
                  </Text> */}
                </Flex>

                {/* Les lignes d'information */}
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      POMPE MODELE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.pompeModeleSelected}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      POMPE PUISSANCE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.pompeSelected} kWh
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      CONSO ANNUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.consoAnnuellePompe} kWh
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      DEPENSE CHAUFFAGE ACTUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.pompeModeleSelected === "0"
                      ? 0
                      : formData.depenseChauffage?.toFixed()}{" "}
                    €
                  </Text>
                </Flex>

                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      DEPENSE FUTURE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.depenseFuturePompe?.toFixed()} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      COUT DU PROJET
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.coutProjetPompe} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      AIDE MAPRIME RENOV
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.maPrimePompe} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      AIDE CEE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.aidesCeePompe} €
                  </Text>
                </Flex>
              </VStack>
            </Box>
            <Divider
              mx={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"546px"}
              color={"#EAEAEA"}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              w={["230px", "230px", "260px", "338px"]}
              h="456px"
              borderRadius="20px"
              padding="20px"
              borderWidth="1px"
              borderColor="#E2E8F0"
            >
              <Box
                boxSize={["230px", "230px", "230px", "300px"]}
                borderRadius="20px"
                bg="#F7F9FE"
                alignItems={"center"}
                display={"flex"}
                justifyContent={"center"}
              >
                <Image
                  src={Picto2} // Remplacez Picto3 par le chemin d'accès correct à votre icône
                  boxSize="50px"
                />
              </Box>

              <VStack
                divider={<StackDivider borderColor="#CBD5E0" />}
                align="stretch"
                w="full"
              >
                {/* Les éléments Label et Account */}
                <Flex mt={"20px"} justifyContent="space-between" mb={2}>
                  {/* <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Label
                  </Text>
                  <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Account
                  </Text> */}
                </Flex>

                {/* Les lignes d'information */}
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      CONSO ANNUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {Math.round(formData.consoAnnuelleBallon)} kWh
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      DEPENSE ACTUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.ballonThermoSelected === "0"
                      ? 0
                      : formData.depenseECS?.toFixed()}{" "}
                    €
                  </Text>
                </Flex>

                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      DEPENSE FUTURE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.depenseFutureBallon?.toFixed()} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      COUT DU PROJET
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.coutProjetBallon} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      AIDE MAPRIME RENOV
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.maPrimeBallon} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      AIDES CEE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.aidesCeeBallon} €
                  </Text>
                </Flex>
              </VStack>
            </Box>
            <Divider
              mx={"20px"}
              orientation="vertical"
              border={"1px solid"}
              h={"546px"}
              color={"#EAEAEA"}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              w={["230px", "230px", "260px", "338px"]}
              h="546px"
              borderRadius="20px"
              padding="20px"
              borderWidth="1px"
              borderColor="#E2E8F0"
            >
              <Box
                boxSize={["230px", "230px", "230px", "300px"]}
                borderRadius="20px"
                bg="#F7F9FE"
                alignItems={"center"}
                display={"flex"}
                justifyContent={"center"}
                h={"160px"}
                gap={"20px"}
              >
                <Image src={Picto4} boxSize="50px" />
              </Box>

              <VStack
                divider={<StackDivider borderColor="#CBD5E0" />}
                align="stretch"
                w="full"
                mb={"20px"}
              >
                {/* Les éléments Label et Account */}
                <Flex mt={"20px"} justifyContent="space-between" mb={2}>
                  {/* <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Label
                  </Text>
                  <Text fontSize="xs" fontWeight="bold" color="#737373">
                    Account
                  </Text> */}
                </Flex>

                {/* Les lignes d'information */}
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      PANNEAUX SELECTIONNES
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.panneauxSelected}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      CONSO ANNUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.panneauxSelected === "0"
                      ? 0
                      : Math.round(formData.consoAnnuellePanneaux)}{" "}
                    kWh
                  </Text>
                </Flex>

                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      PRODUCTION ANNUELLE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {Math.round(formData.productionAnnuellePanneaux)} kWh
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      DEPENSE FUTURE
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.depenseFuturePanneaux} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      COUT DU PROJET
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.coutProjetPanneaux} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      PRIME AUTO CONSOMATION
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.primeAutoConsoPanneaux} €
                  </Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <HStack spacing={2}>
                    <Image src={Picto1} boxSize="8px" />
                    <Text
                      fontSize={["8px", "10px", "11px", "12px"]}
                      color="#737373"
                    >
                      RECUPERATION TVA
                    </Text>
                  </HStack>
                  <Text
                    fontSize={["8px", "10px", "11px", "12px"]}
                    fontWeight="bold"
                  >
                    {formData.recuperationTvaPanneaux} €
                  </Text>
                </Flex>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="238px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={onNext}
        >
          Confirme les informations
        </Button>
      </Flex>
    </Box>
  );
};

export default StepOne;
